<script lang="ts" setup>

</script>

<template>
  <div class="min-h-screen flex justify-center items-center bg-surface-50 dark:bg-surface-950 overflow-auto">
    <Card class="w-full md:w-1/2 bg-surface-0 dark:bg-surface-800 py-8 px-8 sm:px-8" style="border-radius: 40px">
      <template #content>
        <slot />
      </template>
    </Card>
  </div>
  <ClientOnly>
    <Toast />
  </ClientOnly>
</template>

<style lang="scss"></style>